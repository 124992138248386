.preddefNotes {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
  animation: items-appear 1s ease-out forwards;
}

.preddefNotes ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  max-height: 20rem;
}

.actions {
  display: flex;
  justify-content: center;
}

.note {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.label {
  font-weight: bold;
}

.input {
  font: inherit;
  background-color: #0fecd98a;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 50%;
  height: 40px;
  text-align: left;
  padding: 0.25rem;
  margin-top: 2.5rem;
  margin-left: 1rem;
  margin-right: -10rem;
}
