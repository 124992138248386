.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.actions {
  text-align: right;
}
