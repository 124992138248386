.actions {
  text-align: center;
}

.tagList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tagList li {
  height: 2rem;
  margin-left: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.tagList label {
  cursor: pointer;
}
