/* The switch - the box around the slider */
.card {
  margin: 2rem auto;
  margin-bottom: 4rem;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-color: white;
  animation: meals-appear 1s ease-out forwards;
  max-width: 65rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2ccfc2e1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.control input,
.control textarea {
  font: inherit;
  padding: 0.35rem;
  border-radius: 4px;
  background-color: #f0f0f0;
  border: 1px solid #c1d1d1;
  display: block;
  width: 100%;
  font-size: 1.25rem;
}

.control input:focus,
.control textarea:focus {
  background-color: #cbf8f8;
  outline-color: teal;
}

.lineControl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.tagSpan {
  margin-left: 1rem;
  cursor: pointer;
}

.lineControl label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.lineControl input,
.lineControl textarea {
  font: inherit;
  padding: 0.35rem;
  border-radius: 4px;
  background-color: #f0f0f0;
  border: 1px solid #c1d1d1;
  display: block;
  width: 40%;
  font-size: 1.25rem;
}

.lineControl select {
  height: 100%;
}

.actions {
  text-align: right;
}

@keyframes meals-appear {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
