.button {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #0fecd98a;
  color: white;
  padding: 0.75rem 2.5rem;
  margin-left: 0.5rem;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
  font-weight: bold;
}

.button:hover,
.button:active {
  background-color: #0fecd9e1;
}

.button:disabled {
  background-color: #7c7c7c;
}
