* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 0;
  background-color: #e7f8f8;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.focused {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.btn {
  cursor: pointer;
  text-decoration: none;
  font: inherit;
  border: none;
  background-color: #0fecd98a;
  color: white;
  padding: 0.75rem 2.5rem;
  margin-left: 0.5rem;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
  font-weight: bold;
}

.btn:hover,
.btn:active {
  background-color: #0fecd9e1;
}

.btn--flat {
  cursor: pointer;
  font: inherit;
  color: teal;
  border: none;
  background-color: transparent;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
}

.btn--flat:hover,
.btn--flat:active {
  background-color: teal;
  color: white;
}

.hidden {
  display: none;
}

@keyframes card-appear {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cardSection {
  max-width: 60rem;
  width: 100%;
  margin: 2rem auto;
  animation: card-appear 1s ease-out forwards;
  justify-content: center;
}

.cardSection ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
