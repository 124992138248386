.colorPicker {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colorPicker label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.colorPicker input {
  margin: 0.5rem;
  min-width: 8rem;
  min-height: 2.5rem;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.control input,
.control textarea {
  font: inherit;
  padding: 0.35rem;
  border-radius: 4px;
  background-color: #f0f0f0;
  border: 1px solid #c1d1d1;
  display: block;
  width: 100%;
  font-size: 1.25rem;
}

.control input:focus,
.control textarea:focus {
  background-color: #cbf8f8;
  outline-color: teal;
}

.lineControl {
  display: flex;
  justify-content: space-between;
}

.lineControl label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.lineControl input,
.lineControl textarea {
  font: inherit;
  padding: 0.35rem;
  border-radius: 4px;
  background-color: #f0f0f0;
  border: 1px solid #c1d1d1;
  display: block;
  width: 60%;
  font-size: 1.25rem;
}

.lineControl select {
  height: 100%;
}

.actions {
  text-align: right;
}
